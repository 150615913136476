import { useAnalytics } from "~/composables/Analytics";
import { useCalculator } from "~/composables/Calculator";

export const useDigitalOrder = () => {

  const leasing = useLeasingParams();
  const storage = useSession();
  const router = useRouter();
  const backend = useBackend();
  const analytics = useAnalytics();
  const route = useRoute();
  const calculator = useCalculator();
  const utm = useUtm();

  const digitalOrderData = ref();

  const car = ref();
  const pricing = ref({});

  const liczakUuid = ref();
  const loadPage = ref(true)

  const actualPage = ref(1);
  const actualStep = ref(2);

  const loading = ref(false)
  const errors = ref({})

  const contactData = useState('doContactData', () => ({
    email: "",
    phone: "",
    agree: false
  }))

  const clientDTO = useState('doClientDTO', () => ({additional_info: {}}));
  const connectedPerson = useState('doConnectedPerson', () => ({}));
  const spousePerson = useState('doSpousePerson', () => ({}));
  const client = useState('doClient', () => leasing.getCurrentParams().client)

  const mainAddress = useState('doMainAddress', () => ({}));
  const correspondAddress = useState('doCorrespondAddress', () => ({}));

  const createDigitalOrder = async (groupId, brand, model, installment, price) => {
    const params = leasing.getCurrentParams();
    params.groupId = groupId;

    brand = brand === undefined ? route.params.brandOrId : brand;
    model = model === undefined ? route.params.model : model;

    const results = await backend.createDigitalOrder(params);

    if (results) {
      storage.setItem('__transaction', {
        ...storage.getItem('__transaction'),
        [results.uuid]: results.secret
      });

      let storageData = storage.getItem('leasingParams')

      let isEntrepreneur = storageData?.client === 'entrepreneur'

      analytics.analyticsLayer({
        'event': 'trans_form_cta',
        'brand': brand,
        'model': model,
        'trans_rata': installment,
        'trans_cena': isEntrepreneur ? price : price * 1.23,
      })

      analytics.analyticsLayer({
        'event': 'trans_form_step1',
        'brand': brand,
        'model': model,
        'trans_rata': installment,
        'trans_cena': isEntrepreneur ? price : price * 1.23,
      })

      router.push({
        name: 'transaction-form',
        params: {
          uuid: results.uuid,
        }
      });
    }
  }

  const createDigitalOrderFromMarket = (brand, model, version, productionYear, state, price, modelId) => {
    let groupId = `eurotax_${brand}_${model}_${version}_${productionYear}_${state}_${price}_${modelId}`
    let installment = 100 //todo

    createDigitalOrder(groupId, brand, model, installment, price)
  }

  const loadOrder = () => new Promise(async success => {
    if (process.server) {
      success([]);
      return;
    }
    let secret = storage.getItem('__transaction')?.[route.params.uuid] ?? 'null'
    const result = await backend.getDigitalOrder(route.params.uuid, secret)

    if (result.data.error) {
      window.location.href = "/";
      return;
    }

    if (result.data?.clientDTO) {

      client.value = result.data?.client;

      clientDTO.value = result.data.clientDTO;
      if (!result.data.clientDTO.additional_info) {
        clientDTO.value.additional_info = {}
      }
      connectedPerson.value = result.data.connectedPerson[0]
      spousePerson.value = result.data.connectedPerson?.[1];
      mainAddress.value = result.data.clientDTO.addresses?.[0];
      correspondAddress.value = result.data.clientDTO.addresses?.[1];

      if (result.data?.clientDTO?.contact_forms?.length > 0) {
        if (result.data.clientDTO.contact_forms[0]?.type === 'email') {
          contactData.value.email = result.data.clientDTO.contact_forms[0]?.contact
          contactData.value.phone = result.data.clientDTO.contact_forms[1]?.contact
        } else {
          contactData.value.email = result.data.clientDTO.contact_forms[1]?.contact
          contactData.value.phone = result.data.clientDTO.contact_forms[0]?.contact
        }
      }
    }

    car.value = await loadCar(
      result.data.groupId,
      result.data.actualStep,
      result.data.actualPage
    )

    liczakUuid.value = result.data?.liczak_uuid
    digitalOrderData.value = result.data;
    success(result.data)
  })

  const loadCar = (groupId, step, page) =>
    new Promise(async (success, failure) => {
      const result = await backend.getCar(groupId, true, true);

      if (typeof result.data.pop !== "undefined") {
        failure(createError({
          statusCode: 404,
          message: "not found"
        }));
        return;
      }

      const [carPricing] = await calculator.getOneInstallment(result.data);
      pricing.value = carPricing;

      result.data.installment = carPricing.installment;
      loadPage.value = false

      actualStep.value = step ?? 2
      actualPage.value = page ?? 1

      success(result.data)
    })

  const getErrors = () => {
    return errors.value
  }

  const sendAnalyticsByStep = (step) => {
    let storageData = storage.getItem('__transaction');
    let leasingParams = leasing.refParams.value;
    let isEntrepreneur = leasingParams['client'] === 'entrepreneur'

    switch(step) {
      case 2:
        analytics.analyticsLayer({
          'event': 'trans_form_step2',
          'transactionId': route.params.uuid,
          'email': storageData?.email,
          'phone': storageData?.phone,
          'brand': car.value.brandName,
          'model': car.value.modelName,
          'trans_rata': pricing.value.installment,
          'trans_cena': isEntrepreneur ? car.value.price : car.value.price * 1.23,
          'product_id': car.value.group_id
        })
        break;
      case 3:
        analytics.analyticsLayer({
          'event': 'trans_form_step3',
          'brand': car.value.brandName,
          'model': car.value.modelName,
          'cal_klient': leasingParams['client'],
          'cal_okres': leasingParams['duration'],
          'cal_oplata': leasingParams['initialFee'],
          'cal_wykup': leasingParams['repurchase'],
          'trans_rata': pricing.value.installment,
          'trans_cena': isEntrepreneur ? car.value.price : car.value.price * 1.23,
          'product_id': car.value.group_id
        });
        break;
      case 4:
        analytics.analyticsLayer({
          'event': 'trans_form_step4',
          'brand': car.value.brandName,
          'model': car.value.modelName,
          'trans_rata': pricing.value.installment,
          'trans_cena': isEntrepreneur ? car.value.price : car.value.price * 1.23,
          'product_id': car.value.group_id
        });
        break;
      case 5:
        analytics.analyticsLayer({
          'event': 'trans_form_step5',
          'brand': car.value.brandName,
          'model': car.value.modelName,
          'trans_rata': pricing.value.installment,
          'trans_cena': isEntrepreneur ? car.value.price : car.value.price * 1.23,
          'product_id': car.value.group_id
        });
        break;
    }
  }

  const handleSteps = async (data) => {
    loading.value = true;
    clearErrors();

    const steps = {
      2: handleStep2,
      3: handleStep3,
      4: handleStep4,
      5: handleStep5,
      6: handleStep6
    }

    const actualStepHandler = steps[actualStep.value];
    if (!actualStepHandler) return null;
    const result =  await actualStepHandler(data);
    return result;
  }

  const handleStep2 = async (data) => {

    const response = await backend.digitalOrderStoreContact({
      ...data, ...utm.getUtmData(), uuid: route.params.uuid, url: utm.getUrlWithUtmParameters()
    })

    loading.value = false
    if (response.errors) {
      setErrors(response.response.errors);
      return false;
    }
    if (!response.uuid) return null;

    storage.setItem('__transaction', {
      ...storage.getItem('__transaction'),
      email: data.email,
      phone: data.phone,
    })

    return true;
  }

  const handleStep3 = async (data) => {
    const params = leasing.getCurrentParams();

    const response = await
      backend.leasingParamDigitalOrder({
        ...params, uuid: route.params.uuid, installment: pricing.value.installment, price: pricing.value.price
      });

    loading.value = false
    if (!response.uuid) return null;

    return true;
  }

  const handleStep4 = async (data) => {
    const response = await backend.storeShowOffer(route.params.uuid);

    loading.value = false
    if (!response?.data?.uuid) return null
    return true;
  }

  const handleStep5 = async (data) => {
    const params = leasing.getCurrentParams();

    const payload = {
      correspondAddress: data.correspondAddress,
      formCompany: {
        nip: clientDTO.value?.nip,
        companyName: clientDTO.value?.name,
        companyForm: clientDTO.value?.companyForm,
        companyRole: connectedPerson.value?.roles,
        employeesAmount: clientDTO.value?.number_of_employees,
        bookkeepingType: clientDTO.value?.book_keeping_type,
        lastYearIncome: clientDTO.value?.last_year_income,
        numberOfDependents: clientDTO.value?.number_of_dependents
      },
      formPersonalCompany: {
        name: connectedPerson.value?.first_name,
        surname: connectedPerson.value?.last_name,
        documentNumber: connectedPerson.value.document_number,
        documentAuthority: connectedPerson.value.document_authority,
        documentExpiration: connectedPerson.value.document_expiration_date,
        indefinitely: connectedPerson.value.indefinitely,
        pesel: connectedPerson.value.pesel,
        pep: connectedPerson.value.politically_exposed,
        maritalStatus: connectedPerson.value.martial_status,
        propertySeparation: connectedPerson.value.separate_property
      },
      formPersonalCompanySpouse: {
        name: spousePerson.value.first_name,
        surname: spousePerson.value.last_name,
        documentNumber: spousePerson.value.document_number,
        documentAuthority: spousePerson.value.document_authority,
        documentExpiration: spousePerson.value.document_expiration_date,
        indefinitely: spousePerson.value.indefinitely,
        pesel: spousePerson.value.pesel,
        pep: spousePerson.value.politically_exposed
      },
      formCompanyAddress: {
        postalCode: mainAddress.value.postal_code,
        city: mainAddress.value.city,
        street: mainAddress.value.street,
        buildNumber: mainAddress.value.houseNo,
        flatNumber: mainAddress.value.flatNo
      },
      formCompanyCorrespondAddress: {
        postalCode: correspondAddress.value.postal_code,
        city: correspondAddress.value.city,
        street: correspondAddress.value.street,
        buildNumber: correspondAddress.value.houseNo,
        flatNumber: correspondAddress.value.flatNo
      },
      formStatements: {
        statement1: data.statements,
        statement2: data.statements,
        statement3: data.statements,
        statement4: data.statements,
        statement5: data.statements,
        statement6: data.statements
      },
      formConsumer: {
        name: connectedPerson.value?.first_name,
        surname: connectedPerson.value?.last_name,
        documentNumber: connectedPerson.value.document_number,
        documentAuthority: connectedPerson.value.document_authority,
        documentExpiration: connectedPerson.value.document_expiration_date,
        indefinitely: connectedPerson.value.indefinitely,
        pesel: connectedPerson.value.pesel,
        pep: connectedPerson.value.politically_exposed,
        maritalStatus: connectedPerson.value.martial_status,
        propertySeparation: connectedPerson.value.separate_property
      },
      formConsumerSpouse: {
        name: spousePerson.value.first_name,
        surname: spousePerson.value.last_name,
        documentNumber: spousePerson.value.document_number,
        documentAuthority: spousePerson.value.document_authority,
        documentExpiration: spousePerson.value.document_expiration_date,
        indefinitely: spousePerson.value.indefinitely,
        pesel: spousePerson.value.pesel,
        pep: spousePerson.value.politically_exposed
      },
      formConsumerAddress: {
        postalCode: mainAddress.value.postal_code,
        city: mainAddress.value.city,
        street: mainAddress.value.street,
        buildNumber: mainAddress.value.houseNo,
        flatNumber: mainAddress.value.flatNo
      },
      formConsumerCorrespondAddress: {
        postalCode: correspondAddress.value.postal_code,
        city: correspondAddress.value.city,
        street: correspondAddress.value.street,
        buildNumber: correspondAddress.value.houseNo,
        flatNumber: correspondAddress.value.flatNo
      },
      formConsumerEmployment: {
        employerName: clientDTO.value.additional_info.employerName,
        position: clientDTO.value.additional_info.position,
        employmentFrom: clientDTO.value.additional_info.hired_from,
        employmentTo: clientDTO.value.additional_info.hired_to,
        salaryNetto: clientDTO.value.additional_info.average_remuneration,
        houseHold: clientDTO.value.additional_info.people_in_house_hold,
        formEmployerAddress: {
          postalCode: clientDTO.value.additional_info.employer_postal_code,
          city: clientDTO.value.additional_info.employer_city,
          street: clientDTO.value.additional_info.employer_street_name,
          buildNumber: clientDTO.value.additional_info.employer_building_number,
          flatNumber: clientDTO.value.additional_info.employer_flat_number
        },
      }
    };


    // const payloadCleared = {}
    //
    // Object.keys(payload).forEach(key => {
    //   if (typeof payload[key] !== "object") {
    //     payload[key] = typeof payload[key] === "undefined" ? null : payload[key]
    //   } else {
    //     Object.keys(payload[key]).forEach(deepKey => {
    //       if (typeof payload[key][deepKey] !== "object") {
    //         payload[key][deepKey] = typeof payload[key][deepKey] === "undefined" ? null : payload[key][deepKey]
    //       }
    //     })
    //   }
    // });

    const clearPayload = (dirty) => {
      const sanitized = {}
      Object.keys(dirty).forEach(key => {
        if (typeof dirty[key] !== "undefined") {
          if (typeof dirty[key] !== "object") {
            sanitized[key] = dirty[key]
          } else {
            if(dirty[key] !== null) {
              const result = clearPayload(dirty[key]);
              if(Object.keys(result).length) {
                sanitized[key] = clearPayload(dirty[key]);
              }
            }
          }
        }
      })
      return sanitized;
    }

    const collections = []

    const response = await backend.storeDigitalOrderData({
      ...params, ...payload, uuid: route.params.uuid
    });

    loading.value = false

    if (response.errors) {
      setErrors(response.errors);
      return false;
    }
    if (!response.uuid) return null;

    return true;
  }
  const handleStep6 = async () => {
    return;
  }

  // watch(() => clientDTO.value, (v) => console.log(v), {deep: true});

  const setErrors = (errorData) => {
    for (let el in errorData) {
      errors.value[el] = errorData[el][0]
    }
  }

  const clearErrors = () => {
    errors.value = {}
  }

  const setup = async () => new Promise(async (success, failure) => {
    const isConsumerSpouse = car.price > 100000
    const isCompanySpouse = car.price > 100000

    const result = await backend.getDigitalOrderSetup();

    if (typeof result.data.length !== "undefined") {
      failure(createError({
        statusCode: 404,
        message: "not found"
      }));
      return;
    }

    success({
      isConsumerSpouse,
      isCompanySpouse,
      companyForm: new Map(Object.entries(result.data.company_form)),
      companyRoles: new Map(Object.entries(result.data.company_roles)),
      bookKeepingTypes: new Map(Object.entries(result.data.bookkeeping_types)),
      maritalStatuses: new Map(Object.entries(result.data.marital_statuses)),
    });
  })


  return {
    createDigitalOrder,
    createDigitalOrderFromMarket,
    loadOrder,
    loadCar,
    actualStep,
    actualPage,
    car,
    loading,
    handleSteps,
    sendAnalyticsByStep,
    getErrors,
    clientDTO,
    connectedPerson,
    spousePerson,
    contactData,
    mainAddress,
    correspondAddress,
    setup,
    client
  }
}
